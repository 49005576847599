<template>
    <v-dialog v-model="dialog" scrollable max-width="600px">
        <v-card tile class="grey darken-3">
            <v-app-bar dense color="blue-grey darken-2">
                <v-toolbar-title>Auswahl</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field flat solo dense hide-details label="Suche" prepend-inner-icon="mdi-magnify" background-color="blue-grey darken-3"
                    v-model="search" clearable
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn icon>
                    <v-icon>mdi-reload</v-icon>
                </v-btn>
            </v-app-bar>
            <v-card-text style="height: 300px;">
                <v-list two-line class="grey darken-3">
                <v-list-item-group v-model="selectedItem" color="blue-grey lighten-3" @change="selectionChange($event)">
                <template v-for="(item, index) in filteredItems" >
                    <v-list-item :key="item.title">
                        <template v-slot:default="{ active }">
                            <v-list-item-icon><v-icon v-text="item.icon"></v-icon></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                                <v-list-item-subtitle class="text--primary" v-text="item.headline"></v-list-item-subtitle>
                                <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text v-text="item.action"></v-list-item-action-text>
                                <v-icon v-if="!active" color="grey lighten-1">mdi-star-outline</v-icon>
                                <v-icon v-else color="yellow darken-3">mdi-star</v-icon>
                            </v-list-item-action>
                        </template>
                    </v-list-item>
                    <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
                </template>
                </v-list-item-group>
                </v-list>
            </v-card-text>
            <v-divider />
            <v-card-actions>
            <v-btn text class="warning" @click="onCancel()">Abbrechen</v-btn>
            <v-spacer />
            <v-btn v-if="selectedItem != undefined" text class="success" @click="onSubmit()">Übernehmen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
	export default {
	  	name: 'DialogTwo',
		components: {},
	  	props: {
	  	},
		data: () => ({
            loading: false,
            dialog: false,
            search: null,
            selectedItem: undefined,
      items: [
            {
                id: 1,
            action: '15 min',
            headline: 'Brunch this weekend?',
            subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
            title: 'Ali Connors',
            },
            {
                id: 2,
            action: '2 hr',
            headline: 'Summer BBQ',
            subtitle: `Wish I could come, but I'm out of town this weekend.`,
            title: 'me, Scrott, Jennifer',
            },
            {
                id: 3,
            action: '6 hr',
            headline: 'Oui oui',
            subtitle: 'Do you have Paris recommendations? Have you ever been?',
            title: 'Sandra Adams',
            },
            {
                id: 4,
            action: '12 hr',
            headline: 'Birthday gift',
            subtitle: 'Have any ideas about what we should get Heidi for her birthday?',
            title: 'Trevor Hansen',
            },
            {
                id: 5,
            action: '18hr',
            headline: 'Recipe to try',
            subtitle: 'We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
            title: 'Britta Holt',
            },
        ],
        }),
        computed: {
            filteredItems () {
                return this.items.filter(item => {
                    if(!this.search) return this.items
                    return (item.title.toLowerCase().includes(this.search.toLowerCase()))
                })
            },
        },
		methods: {
            onCancel() {
                this.$emit('cancel')
                this.dialog = false
            },
            onSubmit() {
                console.log('selected:', this.selectedItem)
                this.$emit('submit', this.filteredItems[this.selectedItem])
                this.dialog = false
            },
            selectionChange(e) {
                console.log('selection changed:', e)
            },
        },
		mounted() {
            console.log('dialog two mounted')
            this.dialog = true
        },
        beforeDestroy() {
            console.log('destroying dialog two')
        },
	  	watch: {

        },
    }
</script>
<style scoped>
</style>