<template>
    <v-container>
        <v-btn @click="goTest1">TEST1</v-btn>
        <v-btn @click="goTest2">TEST2</v-btn>
        <v-btn @click="goTest3">TEST3</v-btn>
        <v-overlay absolute :value="loading">
        	<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
        </v-overlay>
	    <v-snackbar v-model="snack.active" :color="snack.type"> {{ snack.text }}
      		<v-btn text dark @click="snack.active = false">Ok</v-btn>
    	</v-snackbar>
        <DialogOne v-if="openDialogOne" :active="openDialogOne" @cancel="onCancel()" @submit="onSubmit($event)" />
        <DialogTwo v-if="openDialogTwo" @cancel="onCancel" @submit="onSubmit($event)" />
        <DialogThree v-if="openDialogThree" @cancel="onCancel" @submit="onSubmit($event)" />
    </v-container>
</template>

<script>
import DialogOne from './DialogOne'
import DialogTwo from './DialogTwo'
import DialogThree from './DialogThree'
export default {
    name: 'designtests',
    components: {
        DialogOne, DialogTwo, DialogThree,
    },
    props: {
    },
    data: () => ({
        loading: false,
        openDialogOne: false,
        openDialogTwo: false,
        openDialogThree: false,
        snack: { active: false, type: 'success', text: 'saved',	},
    }),
    computed: {
    },
    methods: {
        goTest1() {
            this.openDialogOne = true
        },
        goTest2() {
            this.openDialogTwo = true
        },
        goTest3() {
            this.openDialogThree = true
        },
        onCancel() {
            this.openDialogOne = false
            this.openDialogTwo = false
            this.openDialogThree = false
        },
        onSubmit(e) {
            console.log(e)
            this.openDialogOne = false
            this.openDialogTwo = false
        },
    },
    mounted() {},
    watch: {},
}
</script>
<style scoped>
</style>